import React from 'react';

import {Button, CircularProgress, Divider, Toolbar, Typography} from "@material-ui/core";

import ProviderCredentialService from "../../service/ProviderCredentialService";
import Notification from "../../common/Notification";
import CustomToolbar from "../../common/CustomToolbar";
import ProviderCredentialTable from "./ProviderCredentialTable";
import TextField from '@material-ui/core/TextField';
import { includes } from '../../../helpers';

class ProviderCredentialController extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      credentials: [],
      loading: false,
      error: null,
      notificationMessage: '',
      notificationVariant: 'success',
      filteredData: [],
      filteredValue: ''
    };
  };

  componentWillMount() {
    this.fetchCredentials();
  };

  filterCredentials = name => event => {
      var value = event.target.value;
      if (value) {
          var filtered = this.state.credentials.filter(credential => includes(credential.organization_id, value) || includes(credential.name, value) );
          this.setState({
            filterValue: event.target.value,
            filteredData: filtered
          })
      } else {
        this.setState({
          filteredData: this.state.credentials,
          filterValue: ''
        });
      }
    };

  fetchCredentials = function() {
    this.setState({loading: true});
    ProviderCredentialService.getAllCredentials()
      .then((data) => {
        this.setState({
          loading: false,
          credentials: data,
          filteredData: data
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: error.message
        });
      });
  };

  render() {
    const {loading, error, filteredData, notificationMessage, notificationVariant} = this.state;

    if (loading) {
      return <div className="loading-container"><CircularProgress/></div>
    }

    if (error) {
      return <div className='error'>{error}</div>
    }

    return (
      <div>
        <CustomToolbar/>

        <Toolbar disableGutters={true}>
           <Typography variant="h5" style={{flexGrow: 1}}>Provider Credentials</Typography>
           <TextField
                     id="outlined-search"
                     label="Filter by Name | Organization"
                     type="search"
                     className="filter-input"
                     variant="outlined"
                     margin="dense"
                     value={this.state.filterValue}
                     onChange={this.filterCredentials()}/>
           <Button href={ process.env.PUBLIC_URL + "/create/credential" } variant="contained" color="primary">
             Create New Credentials
           </Button>
        </Toolbar>
        <Divider/>

        <ProviderCredentialTable credentials={filteredData}/>
        <Notification ref={this.notification} variant={notificationVariant} message={notificationMessage}/>
      </div>
    );

  };

};

export default ProviderCredentialController;