import React from 'react';

import {Button} from "@material-ui/core";
import VendorService from "../service/VendorService";
import CustomToolbar from "../common/CustomToolbar";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import TestingIntegrationService from "../service/TestingIntegrationService";

class TestingCallDialog extends React.Component {

 constructor(props) {
    super(props);
    this.state = {
      vendors:[],
      selectedVendor:'',
      originNumber: '',
      destinationNumber:'',
      payload:'{\n}',
      madeCall: false
    };
  };

  componentWillMount() {
    this.fetchVendors();
  };

 handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  isValid = function() {
    return this.state.originNumber && this.state.destinationNumber && this.state.selectedVendor;
  };

  getVendorById = function(vendorId) {
    var result;
    this.state.vendors.forEach(vendor => {
      if (vendor.id === vendorId) {
        result = vendor;
      }
    });
    return result;
  };

  makeCall = () => {
    var vendor = this.getVendorById(this.state.selectedVendor);
    const payload = {
      organization_id: vendor.organization_id,
      destination_phone_number: this.state.destinationNumber,
      origin_phone_number: this.state.originNumber,
      user_ip: 1
    };
    if (vendor.vendor_type === 'USER') {
      payload['user_id'] = vendor.external_id;
    }
    if (vendor.vendor_type === 'BUSINESS_UNIT') {
      payload['business_unit_id'] = vendor.external_id;
    }
    if (vendor.vendor_type === 'DIVISION') {
      payload['division_id'] = vendor.external_id;
    }
    this.setState({
      payload: JSON.stringify(payload, null, "\t"),
      madeCall: true
    });

    TestingIntegrationService.makeCall(payload)
      .then(data => {})
      .catch(error => {});
  };

  getMessage = function() {
    return this.state.madeCall ? "Making call..." : "";
  }

  fetchVendors = function() {
    VendorService.getAllVendors()
      .then((data) => {
        this.setState({
          vendors: data
        });
      })
      .catch((error) => {
        this.setState({
          error: error
        });
      });
  };

  resetAllFields = () => {
    this.setState({
      selectedVendor:'',
      originNumber: '',
      destinationNumber:'',
      payload:'{\n}',
      madeCall: false
    });
  };

  reset = function() {
    return this.state.madeCall
        ? (
          <Button variant="contained" onClick={this.resetAllFields} color="primary">
            Reset
          </Button>
        ) : "";
  };

  render() {
    return (
      <div>
        <CustomToolbar/>
        <div className="test-integration-container">
          <TextField
              id="outlined-textarea"
              label="Origin Number"
              placeholder="Placeholder"
              className="sms"
              margin="dense"
              variant="outlined"
              value={this.state.originNumber}
              onChange={this.handleChange('originNumber')}
            />
           <br/><br/>
           <TextField
              id="outlined-textarea"
              label="Destination Number"
              placeholder="Placeholder"
              className="sms"
              margin="dense"
              variant="outlined"
              value={this.state.destinationNumber}
              onChange={this.handleChange('destinationNumber')}
            />
           <br/><br/>
           <TextField
                  id="vendor"
                  select
                  className="sms"
                  label="Vendor"
                  variant="outlined"
                  value={this.state.selectedVendor}
                  margin="dense"
                  onChange={this.handleChange('selectedVendor')}
                >
            <MenuItem value="" disabled>Select Vendor</MenuItem>
            {this.state.vendors
            .map(vendor => (
              <MenuItem key={vendor.id} value={vendor.id}>
                {vendor.name}
              </MenuItem>
            ))
            }
        </TextField>
        <br/><br/>
        <Button variant="contained" onClick={this.makeCall} color="primary" disabled={!this.isValid()}>
          Make Call
        </Button>&nbsp;&nbsp;{this.reset()}
        &nbsp;&nbsp;&nbsp;{this.getMessage()}
        <br/><br/><br/>
         <TextField
              id="outlined-textarea"
              label="Payload"
              placeholder="Placeholder"
              className="payload"
              margin="dense"
              variant="outlined"
              disabled
              multiline
              rows="7"
              value={this.state.payload}
            />
        </div>
      </div>
    );
  };
}

export default TestingCallDialog;