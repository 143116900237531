import React from 'react';

import {Button} from "@material-ui/core";

import CustomToolbar from "../common/CustomToolbar";

class TestingIntegrationDialog extends React.Component {

  render() {
    return (
      <div>
        <CustomToolbar/>
        <div className="test-integration-container">
            <Button href="/check/test_sms" variant="contained" color="primary">
              Test SMS Sending
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button href="/check/test_call" variant="contained" color="primary">
              Test Call
            </Button>
        </div>
      </div>
    );
  };
}

export default TestingIntegrationDialog;