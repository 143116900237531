import React, {PureComponent} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

class Confirmation extends PureComponent {

    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.confirm = this.confirm.bind(this);
    }

    close() {
        this.props.closeChange();
    }

    confirm() {
        this.props.confirm();
    }

    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                onClose={this.close}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
                <DialogActions>
                    <Button onClick={this.close} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.confirm} color="primary" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default Confirmation;