import environment                      from '../../app.environment';

const API_URL = environment.REACT_APP_API_URL;

function handleResponse(response) {
  return response.json()
    .catch((response) => {

      return Promise.reject(response.status);
    }).then(json => {

    if (response.ok) {
      return json;
    }

    return Promise.reject(json);
  });
};

const ProviderPhoneService = {

  getAllPhonesForCredential: async function(credentialId, vendorId) {
    if (vendorId)
      return fetch(`${API_URL}/credentials/${credentialId}/phones?vendorId=${vendorId}`)
        .then(handleResponse)
    return fetch(`${API_URL}/credentials/${credentialId}/phones`)
        .then(handleResponse)
  },

  getAllPhonesForCredentialExcludeInUsage: async function(credentialId) {
      return fetch(`${API_URL}/credentials/${credentialId}/phones?showAll=true`)
          .then(handleResponse)
  },

  updateProviderPhone: async function(data) {
    let reqOptions = {
      method: "PUT",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/credentials/${data.provider_credential_id}/phones/${data.id}`, reqOptions)
      .then(handleResponse)
  },

  buyPhoneNumber: async function(credentialId, data) {
    let reqOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/credentials/${credentialId}/phones`, reqOptions)
      .then(handleResponse)
  },

  addPhoneNumber: async function(credentialId, data) {
    let reqOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };

    return fetch(`${API_URL}/credentials/${credentialId}/phones`, reqOptions)
        .then(handleResponse)
  },

  fetchAvailablePhoneNumbers: async function(areaCode, providerId, capabilities, countryCode) {
    return fetch(`${API_URL}/credentials/${providerId}/available-phones?areaCode=${areaCode}&capabilities=${capabilities}&countryCode=${countryCode}`)
      .then(handleResponse)
  }
};

export default ProviderPhoneService;