import React from 'react';
import {MuiThemeProvider} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";
import {Switch, Route, Redirect} from "react-router-dom";

import theme from './../theme'
import NotFound from "./common/NotFound";
import VendorController from "./controllers/vendor/VendorController";
import ProviderCredentialController from "./controllers/providercredential/ProviderCredentialController"
import ProviderCredentialDialog from "./controllers/providercredential/ProviderCredentialDialog"
import VendorDialog from "./controllers/vendor/VendorDialog";
import TestingIntegrationDialog from "./integration/TestingIntegrationDialog"
import TestingCallDialog from "./integration/TestingCallDialog"
import TestingSmsDialog from "./integration/TestingSmsDialog"
import TestingEmailDialog from "./integration/TestingEmailDialog"

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  };

  render() {
    const {error} = this.state;

    if (error) {
      return <div className='error'>{error}</div>
    }

    return (
      <div>
        <MuiThemeProvider theme={theme}>
           <Divider/>

           <Switch>
             <Route exact path="/" render={() => (<Redirect to="/vendors"/>)}/>
             <Route exact path="/vendors/:vendorId/view" render={routeProps => <VendorDialog {...routeProps} isView="true"/>}/>
             <Route exact path="/vendors/:vendorId/edit" component={VendorDialog}/>
             <Route exact path="/vendors" component={VendorController}/>
             <Route exact path="/create/vendor" component={VendorDialog}/>
             <Route exact path="/create/credential" component={ProviderCredentialDialog}/>
             <Route exact path="/credentials" component={ProviderCredentialController}/>
             <Route exact path="/credentials/:credentialId/view" render={routeProps => <ProviderCredentialDialog {...routeProps} isView="true"/>}/>
             <Route exact path="/credentials/:credentialId/edit" component={ProviderCredentialDialog }/>
             <Route exact path="/check" component={TestingIntegrationDialog}/>
             <Route exact path="/check/test_sms" component={TestingSmsDialog}/>
             <Route exact path="/check/test_call" component={TestingCallDialog}/>
             <Route exact path="/check/test_email" component={TestingEmailDialog}/>
             <Route component={NotFound}/>
           </Switch>
        </MuiThemeProvider>
      </div>
    );
  };
}

export default App;
