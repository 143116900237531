import React from 'react';
import PropTypes from 'prop-types';
import {Snackbar, SnackbarContent, IconButton} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {open} = this.state;
    const {variant, message} = this.props;

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={this.handleClose}
        autoHideDuration={("error" === variant) ? null : 3000}
        >
          <SnackbarContent
          message={<span> {("error" === variant) ? <ErrorIcon className="material-icon"/> : <CheckCircleIcon className="material-icon"/> }{message}</span>}
          style={{"backgroundColor" : ("error" === variant ? "#EB5858" : "#22DACB")}}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon/>
            </IconButton>,
          ]}
          />
      </Snackbar>
    )
  }
}


export default Notification;

Notification.propTypes = {
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
  message: PropTypes.string.isRequired,
};