import environment                      from '../../app.environment';

const API_URL = environment.REACT_APP_INTEGRATION;

function handleResponse(response) {
  return response.json()
    .catch((response) => {

      return Promise.reject(response.status);
    }).then(json => {

    if (response.ok) {
      return json;
    }

    return Promise.reject(json);
  });
};

const TestingIntegrationService = {

  sendSms: async function(payload) {
    let reqOptions = {
      method: "put",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(payload)
    };
    return fetch(`${API_URL}/test/sms`, reqOptions)
      .then(handleResponse)
  },

  makeCall: async function(payload) {
    let reqOptions = {
      method: "put",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(payload)
    };
    return fetch(`${API_URL}/test/call`, reqOptions)
      .then(handleResponse)
  },

  sendEmail: async function(payload) {
    let reqOptions = {
      method: "put",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(payload)
    };
    return fetch(`${API_URL}/test/email`, reqOptions)
      .then(handleResponse)
  },
};

export default TestingIntegrationService;