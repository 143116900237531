import React from 'react';

import {Button} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import CustomToolbar from "../common/CustomToolbar";
import VendorService from "../service/VendorService";
import TestingIntegrationService from "../service/TestingIntegrationService";
import MenuItem from '@material-ui/core/MenuItem';
import VendorCredentialService from "../service/VendorProviderCredentialService";

class TestingEmailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailText: '',
      vendors:[],
      selectedVendor:'',
      receiver:'',
      from:'',
      subject:'',
      isOverflow: false,
      payload:'{\n}',
      vendorsCredentials: [],
      messageSent: false
    };
  };

  componentWillMount() {
    this.fetchVendors();
  }

  isValid = function() {
    return this.state.selectedVendor && this.state.receiver && this.state.emailText && this.state.subject && this.isValidEmails();
  };

  fetchVendorCredentials = function(vendorId) {
    VendorCredentialService.getCredentialsForVendor(vendorId)
      .then(data => {
         var arr = this.state.vendorsCredentials;
         arr.push(data);
         this.setState({
           vendorsCredentials: arr
         });
      })
      .catch(error => {});
  };

  fetchVendors = function() {
    VendorService.getAllVendors()
      .then((data) => {
        data.forEach(vendor => this.fetchVendorCredentials(vendor.id));
        this.setState({
          vendors: data
        });
      })
      .catch((error) => {
        this.setState({
          error: error
        });
      });
  };

  getVendorById = function(vendorId) {
    var result;
    this.state.vendors.forEach(vendor => {
      if (vendor.id === vendorId) {
        result = vendor;
      }
    });
    return result;
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  resetAllFields = () => {
    this.setState({
      emailText: '',
      selectedVendor:'',
      receiver:'',
      subject:'',
      isOverflow: false,
      payload:'{\n}',
      messageSent: false,
      from: ''
    });
  };

  reset = function() {
    return this.state.messageSent
        ? (
          <Button variant="contained" onClick={this.resetAllFields} color="primary">
            Reset
          </Button>
        ) : "";
  };

  isValidEmails = function () {
    var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return re.test(this.state.from) && re.test(this.state.receiver);
  };

  sendEmail = () => {
    var vendor = this.getVendorById(this.state.selectedVendor);
    const payload = {
      organization_id: vendor.organization_id,
      user_ip: 1,
      provider: 'sendgrid',
      mailList: []
    }
    if (vendor.vendor_type === 'USER') {
      payload['user_id'] = vendor.external_id;
    }
    if (vendor.vendor_type === 'BUSINESS_UNIT') {
      payload['business_unit_id'] = vendor.external_id;
    }
    if (vendor.vendor_type === 'DIVISION') {
      payload['division_id'] = vendor.external_id;
    }

    this.fillMailList(payload);

    this.setState({
      payload: JSON.stringify(payload, null, "\t"),
      messageSent: true
    });

    TestingIntegrationService.sendEmail(payload)
      .then(data => {})
      .catch(error => {});
  }

  fillMailList = function(payload) {
    const mail = {
      from: {
        address: this.state.from
      },
      subject: this.state.subject,
      destinationList:[{
        toList: [{
          address: this.state.receiver,
          name: this.extractNameFromEmail(this.state.receiver)
        }],
        ccList:[],
        bccList:[]
      }],
      content: {
        type: 'text',
        body: this.state.emailText
      }
    };
    payload['mailList'].push(mail);
  };

  getMessage = function() {
    return this.state.messageSent ? "Email was sent" : "";
  };

  extractNameFromEmail = function (email) {
    var index = email.indexOf('@');
    return index === -1
            ? email
            : email.substring(0, index);
  };

  render() {
    return (
      <div>
        <CustomToolbar/>
        <div className="test-integration-container">
           <TextField
              id="outlined-textarea"
              label="From Email"
              placeholder="Placeholder"
              className="email"
              margin="dense"
              variant="outlined"
              value={this.state.from}
              onChange={this.handleChange('from')}
           />
           <br/>
           <TextField
              id="outlined-textarea"
              label="Receiver Email"
              placeholder="Placeholder"
              className="email"
              margin="dense"
              variant="outlined"
              value={this.state.receiver}
              onChange={this.handleChange('receiver')}
            />
           <br/>
           <TextField
              id="outlined-textarea"
              label="Subject"
              placeholder="Placeholder"
              className="email"
              margin="dense"
              variant="outlined"
              value={this.state.subject}
              onChange={this.handleChange('subject')}
            />
            <br/>
            <TextField
              id="outlined-textarea"
              label="Input Content"
              placeholder="Placeholder"
              multiline
              className="email"
              rows="10"
              margin="dense"
              variant="outlined"
              value={this.state.emailText}
              onChange={this.handleChange('emailText')}
            />
             <br/>
             <TextField
                      id="vendor"
                      select
                      className="email"
                      label="Vendor"
                      variant="outlined"
                      margin="dense"
                      value={this.state.selectedVendor}
                      onChange={this.handleChange('selectedVendor')}
                    >
              <MenuItem value="" disabled>Select Vendor</MenuItem>
              {this.state.vendors
                .map(vendor => (
                  <MenuItem key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </MenuItem>
                ))
              }
            </TextField>
            <br/><br/>
            <Button variant="contained" onClick={this.sendEmail} color="primary" disabled={!this.isValid()}>
              Send Email
            </Button>
            &nbsp;&nbsp;{this.reset()}
            &nbsp;&nbsp;&nbsp; {this.getMessage()}
            <br/><br/>
            <TextField
              id="outlined-textarea"
              label="Payload"
              placeholder="Placeholder"
              className="payload"
              margin="dense"
              variant="outlined"
              disabled
              multiline
              rows="10"
              value={this.state.payload}
            />
        </div>
      </div>
    );
  };
}

export default TestingEmailDialog;