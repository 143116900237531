import React from 'react';
import {Button, CircularProgress, Divider, Toolbar, Typography} from "@material-ui/core";

import ProviderCredentialService from "../../service/ProviderCredentialService";
import ProviderPhoneService from "../../service/ProviderPhoneService";
import CustomToolbar from "../../common/CustomToolbar";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import ActionMenuProviderCredential from "./ActionMenuProviderCredential";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';

class ProviderCredentialDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      availableCapabilities: [],
      availableProviders: [],
      notificationMessage: '',
      notificationVariant: 'success',
      credential_name: '',
      organization_id: '',
      auth_user: '',
      auth_token: '',
      provider: '',
      capabilities: [],
      hasError: true,
      phoneNumbers: [],
      checked: [],
        isBtnDisabled: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  };

  componentWillMount() {
    this.fetchAvailableCapabilities();
    this.fetchAvailableProviders();

    const credentialId = this.props.match.params.credentialId;
    if (credentialId) {
      this.fetchProviderCredentialData(credentialId);
      this.fetchPhoneNumbers(credentialId);
    }

  };

  getCheckedValue = name => {
    return this.state.checked[name] ? true : false;
  };

  // todo
  handleSubmit(event) {
      this.setState({isBtnDisabled: true});
    event.preventDefault();

    const data = {
      name: this.state.credential_name,
      organization_id: this.state.organization_id,
      auth_user: this.state.auth_user,
      auth_token: this.state.auth_token,
      capabilities: this.state.capabilities,
      provider: this.state.provider
    };

    if (this.state.credential && this.state.credential.id) {
      data['id'] = this.state.credential.id;
    }

    this.state.phoneNumbers.forEach(phone => {
      if (this.isChecked(phone.status) !== this.getCheckedValue(phone.id)) {

        ProviderPhoneService.updateProviderPhone(phone)
          .then((data) => {})
          .catch((error) => {});
      }
    });

    if (this.state.credential) {
      // update
      ProviderCredentialService.updateCredentials(data)
      .then((data) => {
        window.location.href = "/credentials/" + data.id + "/view" ;
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false
        });
      })
          .finally(() => this.setState({isBtnDisabled: false}));
    } else {
      // save new
      ProviderCredentialService.saveCredentials(data)
      .then((data) => {
        window.location.href = "/credentials/" + data.id + "/view" ;
      })
      .catch(error => {
        this.setState({
          error: error.message,
          loading: false
        });
      })
          .finally(() => this.setState({isBtnDisabled: false}));
    }

  };

  isChecked = function(status) {
    return status === "ACTIVE";
  };

  fetchAvailableCapabilities() {
    ProviderCredentialService.getCapabilities()
      .then((data) => {
        this.setState({
          availableCapabilities: data
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message
        });
      });
  };

  fetchAvailableProviders() {
    ProviderCredentialService.getAvailableProviders()
      .then((data) => {
        this.setState({
          availableProviders: data
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message
        });
      });
  };

  fetchPhoneNumbers(credentialId) {
    ProviderPhoneService.getAllPhonesForCredentialExcludeInUsage(credentialId)
      .then((data) => {
        this.setState({
          phoneNumbers: data
        });
        data.forEach(phone => {
          const checks = this.state.checked;
          checks[phone.id] = phone.status === "ACTIVE";
          this.setState({
            checked: checks
          });
        });
      })
      .catch((error) => {
      });
  };

  handleSwitch = name => event => {
    const checks = this.state.checked;
    checks[event.target.value] = event.target.checked;
    this.setState({
      checked: checks
    })
  };

  fetchProviderCredentialData(credentialId) {
    ProviderCredentialService.getProviderCredential(credentialId)
      .then((data) => {
        this.setState({
          credential: data,
          credential_name: data.name,
          auth_user: data.auth_user,
          auth_token: data.auth_token,
          organization_id: data.organization_id,
          capabilities: data.capabilities,
          provider: data.provider,
          hasError: false
        });
      })
      .catch((error) => {
        this.setState({
          error: error
        });
      })
  };

  handleChange = name => event => {
    var isValid = true;
    if (this.checkCapabilities(name, event) && this.checkProvider(name, event)) {
      isValid = false;
    }
    this.setState({
      [name]: event.target.value,
      hasError: isValid
    });
  };

  checkCapabilities = function(name, event) {
    if (name === 'capabilities' && event.target.value.length === 0)
      return false;
    return ((name === 'capabilities' && event.target.value > 0) || this.state.capabilities.length > 0) ? true : true;
  };

  checkProvider = function(name, event) {
    return ((name === 'provider' && event.target.value) || this.state.provider) ? true : false;
  };

  isSupportSingleToken() {
    for (var i = 0; i < this.state.capabilities.length; i++) {
      if (this.state.capabilities[i] === 'EMAIL') {
        return true;
      }
    }
    return false;
  };

  render() {
    const {credential, loading, error, phoneNumbers} = this.state;
    const isView = this.props.isView ? true : false;
    const activeMenu = isView ? (<ActionMenuProviderCredential credential={credential} className="menu"/>) : (<div></div>);

    const footerButtons = !isView ? (
            <Grid container spacing={24}>
                  <Grid item xs={4}/>
                  <Grid item xs={4}>
                    <Grid container spacing={16}>
                     <Grid item xs={2}/>
                     <Grid item xs={4}>
                      <Button variant="outlined" color="primary" href={ process.env.PUBLIC_URL + (credential ? ('/credentials/' + credential.id + "/view") : '/credentials')} className="form-buttons">Cancel</Button>
                     </Grid>
                     <Grid item xs={4}>
                       <Button variant="contained" color="primary" type="submit" className="form-buttons"
                         disabled={this.state.hasError || this.state.isBtnDisabled}>{credential ? 'Save' : 'Create'}
                       </Button>
                     </Grid>
                     <Grid item xs={2}/>
                   </Grid>
                  </Grid>
                  <Grid item xs={4}/>
                </Grid>
    ) : (<div></div>);

    if (loading) {
      return <div className="loading-container"><CircularProgress/></div>
    }

    if (error) {
      return <div className='error'>{error}</div>
    }

    const phoneNumbersSection = (credential && phoneNumbers.length > 0)
      ? (
        <Grid item xs={6}>
          <Typography variant="h6">Phone Numbers</Typography>
          <br/>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Capabilities</b></TableCell>
                <TableCell><b>Phone Number</b></TableCell>
                <TableCell><b>Active</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {phoneNumbers.map(phoneNumber => (
                 <TableRow key={phoneNumber.id}>
                   <TableCell>{phoneNumber.capabilities.join(', ')}</TableCell>
                   <TableCell>{phoneNumber.phone_number}</TableCell>
                   <TableCell>
                        <FormControlLabel
                                  control={
                                        <Switch
                                                checked={this.getCheckedValue(phoneNumber.id)}
                                                value={phoneNumber.id}
                                                onChange={this.handleSwitch(this, true)}
                                                color="primary"
                                                className="phone-credentials-input"
                                                disabled={isView}
                                              />
                                  }/>
                    </TableCell>
                  </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )
      : (<div></div>);

    return (
      <div>
        <CustomToolbar />

        <Toolbar disableGutters={true}>
          <Typography variant="h5" style={{flexGrow: 1}}>{isView && credential ? ('Provider Credential ' + credential.name)
            : (credential ? ('Edit Provider Credential ' + credential.name ): 'Create New Provider Credential') }</Typography>
        </Toolbar>
        <Divider/>

        <form onSubmit={this.handleSubmit}>
          <Grid container spacing={24} className="active">
            <Grid item xs={3}>
               <TextField
                          id="name"
                          label="Name"
                          value={this.state.credential_name}
                          onChange={this.handleChange('credential_name')}
                          margin="normal"
                          className="form-input-label"
                          required
                          disabled={isView}/>
                <br/><br/>
                <FormControl className="form-input-label">
                          <InputLabel htmlFor="select-capabilities" disabled={isView}>Capabilities</InputLabel>
                          <Select
                            multiple
                            value={this.state.capabilities}
                            onChange={this.handleChange('capabilities')}
                            input={<Input id="select-capabilities" />}
                            renderValue={selected => selected.join(', ')}
                            disabled={isView || (credential ? true : false)}
                             helperText="Please select provider">

                            {this.state.availableCapabilities.map(name => (
                              <MenuItem key={name} value={name}>
                                <Checkbox checked={this.state.capabilities.indexOf(name) > -1} />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                          {!this.checkCapabilities && <FormHelperText>This is required!</FormHelperText>}
                </FormControl>
                <br/>
                <TextField
                          id="auth_user"
                          label="Auth User"
                          value={this.state.auth_user}
                          onChange={this.handleChange('auth_user')}
                          margin="normal"
                          className="form-input-label-auth"
                          required
                          disabled={isView || this.isSupportSingleToken()}/>
            </Grid>

             <Grid item xs={3}>
               <TextField
                          id="organization_id"
                          label="Organization ID"
                          value={this.state.organization_id}
                          onChange={this.handleChange('organization_id')}
                          margin="normal"
                          className="form-input-label"
                          required
                          disabled={isView || (credential ? true : false)}/>
                <br/>
                <TextField
                         id="select-provider"
                         select
                         label="Select Provider"
                         className="form-input-label"
                         value={this.state.provider}
                         onChange={this.handleChange('provider')}
                         margin="normal"
                         required
                         disabled={isView}>
                  {this.state.availableProviders.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                           id="auth_token"
                           label="Auth Token"
                           value={this.state.auth_token}
                           onChange={this.handleChange('auth_token')}
                           margin="normal"
                           className="form-input-label"
                           required
                           disabled={isView}/>
             </Grid>

             <Grid item xs={6}>
               {activeMenu}
             </Grid>
             <Grid item xs={8}>
               {phoneNumbersSection}
             </Grid>
             <Grid item xs={4}/>
          </Grid>
          {footerButtons}
        </form>
      </div>
    );
  };

};

export default ProviderCredentialDialog;