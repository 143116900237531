import { createMuiTheme } from '@material-ui/core/styles';


export default createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true // No more ripple, on the whole application
    }
  },
  palette: {
    primary: {
      main: "#5caee8",
      light: "#bbdefb",
      dark: "#90caf9",
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: "#ffffff",
      dark: "#e3f2fd",
      contrastText: "#5caee8"
    },
    error: {
      main: "#EB5858"
    },
    text: {
      primary: "#34495e",
      secondary: "#34495e"
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "15px",
    h1: {
      fontSize: "60px",
      fontWeight: 700
    },
    h2: {
      fontSize: "48px",
      fontWeight: 400
    },
    h3: {
      fontSize: "40pt",
      fontWeight: 500
    },
    h4: {
      fontSize: "36px",
      fontWeight: 500
    },
    h5: {
      fontSize: "32px",
      fontWeight: "medium",
    },
    h6: {
      fontSize: "24px",
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: "18px",
      fontWeight: 700
    },
    subtitle2: {
      fontSize: "14px",
      fontWeight: 700,
      color: "#ffffff"
    },
    body1: {
      fontSize: "15px",
      fontWeight: 500
    },
    button: {
      fontSize: "15px",
      fontWeight: 700,
      textTransform: 'none'
    }
  },
  overrides: {
    MuiTable: {
      root: {
        marginLeft: "2%",
        marginRight: "2%",
        width: "96%"
      }
    },
    MuiTableRow: {
      head: {
        fontSize: '17px',
        fontWeight: 'medium'
      },
      root: {
        fontSize: '15px'
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: "14px"
      }
    },
    MuiToolbar: {
      root: {
        paddingLeft: "2%",
        paddingRight: "2%",
      },
      dense: {
        minHeight: "20px"
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "10px"
      }
    },
    MuiLink: {
      root: {
        color: "primary",
        underline: "none"
      }
    }
  }
});