import React from 'react';

import {Button, CircularProgress, Divider, Toolbar, Typography} from "@material-ui/core";
import VendorService from "../../service/VendorService";
import Notification from "../../common/Notification";
import VendorTable from "./VendorTable";
import CustomToolbar from "../../common/CustomToolbar";
import TextField from '@material-ui/core/TextField';
import { includes } from '../../../helpers';

class VendorController extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vendors: [],
      loading: false,
      error: null,
      notificationMessage: '',
      notificationVariant: 'success',
      filterValue: '',
      filteredVendors: []
    };
  }

  componentWillMount() {
    this.fetchVendors();
  }

  fetchVendors = () => {
    this.setState({loading: true});
    VendorService.getAllVendors()
      .then((data) => {
        this.setState({
          vendors: data,
          loading: false,
          filteredVendors: data
        });
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false
        });
      });
  };



  filterVendors = name => event => {
    var value = event.target.value;
    if (value) {
        var filtered = this.state.vendors.filter(vendor => includes(vendor.organization_id, value) || includes(vendor.name, value));
        this.setState({
          filterValue: event.target.value,
          filteredVendors: filtered
        })
    } else {
      this.setState({
        filteredVendors: this.state.vendors,
        filterValue: ''
      });
    }
  };

  render() {
    const {loading, error, notificationMessage, notificationVariant, filteredVendors} = this.state;

    if (loading) {
      return <div className="loading-container"><CircularProgress/></div>
    }

    if (error) {
      return <div className='error'>{error}</div>
    }

    return (
      <div>
        <CustomToolbar/>

        <Toolbar disableGutters={true}>
           <Typography variant="h5" style={{flexGrow: 1}}>Vendors</Typography>
            <TextField
                     id="outlined-search"
                     label="Filter by Name | Organization"
                     type="search"
                     className="filter-input"
                     variant="outlined"
                     margin="dense"
                     value={this.state.filterValue}
                     onChange={this.filterVendors()}/>
           <Button href={ process.env.PUBLIC_URL + "/create/vendor" } variant="contained" color="primary">
             Create New Vendor
           </Button>
        </Toolbar>
        <Divider/>

        <VendorTable vendors={filteredVendors}/>

        <Notification ref={this.notification} variant={notificationVariant} message={notificationMessage}/>
      </div>
    )

  };

};

export default VendorController;