import React from 'react';
import {Toolbar, Typography} from "@material-ui/core";
import {Link} from 'react-router-dom';
import logo from '../../resources/logo.svg';
import AppBar from '@material-ui/core/AppBar';

class CustomToolbar extends React.Component {

  render() {

    return (
     <div className="toolbar">
     <AppBar position="static">
        <Toolbar disableGutters={true} className="crumbs" variant="dense">
          <Link to="/">
            <img src={logo} alt="logo" className="header-logo"/>
          </Link>
          <Link to="/vendors">
            <Typography>Vendors</Typography>
          </Link>
          <Link to="/credentials">
            <Typography style={{ borderRight: '0.1em solid white', padding: '0.5em' }}>Provider Credentials</Typography>
          </Link>
          <Link to="/check/test_sms">
             <Typography>Test SMS</Typography>
          </Link>
          <Link to="/check/test_call">
            <Typography>Test Call</Typography>
          </Link>
          <Link to="/check/test_email">
            <Typography style={{ borderRight: '0.1em solid white', padding: '0.5em' }}>Test Email</Typography>
          </Link>
          {/*<SearchAppBar />*/}
        </Toolbar>
     </AppBar>
     </div>
    );


  };
};

export default CustomToolbar;