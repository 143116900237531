import environment                      from '../../app.environment';

const API_URL = environment.REACT_APP_API_URL;

function handleResponse(response) {
  return response.json()
    .catch((response) => {

      return Promise.reject(response.status);
    }).then(json => {

    if (response.ok) {
      return json;
    }

    return Promise.reject(json);
  });
};

const VendorService = {

  getAllVendors: async function () {
    return fetch(`${API_URL}/vendors`)
      .then(handleResponse)
  },

  getVendorById: async function(vendorId) {
    return fetch(`${API_URL}/vendors/${vendorId}`)
      .then(handleResponse)
  },

  getAllVendorTypes: async function() {
    return fetch(`${API_URL}/vendors/types`)
      .then(handleResponse)
  },

  updateVendor: async function(data) {
    let reqOptions = {
      method: "put",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/vendors`, reqOptions)
      .then(handleResponse)
  },

  saveVendor: async function(data) {
    let reqOptions = {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/vendors`, reqOptions)
      .then(handleResponse)
  },

  deleteVendor: async function(vendorId) {
    let reqOptions = {
      method: "delete"
    };
    return fetch(`${API_URL}/vendors/${vendorId}`, reqOptions)
      .then(handleResponse)
  }

};

export default VendorService;