import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { init as initApm } from 'elastic-apm-js-base';
import App from "./components/App";
import './index.css'

initApm({
  serviceName: 'communication-manager-ui',
  serverUrl: 'https://apm.internal.ca.dev.stflo.io',
  serviceVersion: '0.0.1'
});

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <App />
  </BrowserRouter>
  , document.getElementById('root'));
