import React from 'react';
import {Button, CircularProgress, Divider, Toolbar, Typography} from "@material-ui/core";

import VendorService from "../../service/VendorService";
import VendorCredentialService from "../../service/VendorProviderCredentialService";
import CustomToolbar from "../../common/CustomToolbar";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProviderCredentialService from "../../service/ProviderCredentialService";
import ActionMenuVendor from './ActionMenuVendor';
import ProviderPhoneService from "../../service/ProviderPhoneService";
import VendorProviderPhoneService from "../../service/VendorProviderPhoneService";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

class VendorDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            availableTypes: [],
            availableCapabilities: [],
            notificationMessage: '',
            notificationVariant: 'success',
            vendorCredentials: [],
            availableCredentials: [],
            hasError: true,
            isEdit: false,
            vendorCapabilitiesMap: new Map(),
            checked: [],
            smsProvider: "",
            callProvider: "",
            smsPhoneNumbers: [],
            callPhoneNumbers: [],
            smsPhone: '',
            callPhone: '',
            vendorProviderPhones: [],
            openDialog: false,
            areaCode: '',
            selectedNumberToBuy: '',
            selectedProviderForBuyingPhone: '',
            availablePhones: [],
            countryCode: '',
            vendorId: '',
            emailProvider: '',
            selectedSmsProvider: '',
            selectedCallProvider: '',
            selectedEmailProvider: '',
            selectedProviderName: '',
            addPhoneValid: false,
            phoneNumber: '',
            selectedCallPhoneNumber: '',
            selectedSmsPhoneNumber: '',
            smsProviderInitial: '',
            callProviderInitial: '',
            emailProviderInitial: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.addPhoneChange = this.addPhoneChange.bind(this);
        this.addPhoneNumber = this.addPhoneNumber.bind(this);
    };

    componentWillMount() {
        this.fetchAvailableVendorTypes();
        this.fetchAvailableCapabilities();

        const vendorId = this.props.match.params.vendorId;
        if (vendorId) {
            this.setState({
                vendorId: vendorId,
                loading: true
            });
            this.fetchVendorProviderCredentials(vendorId);
            this.fetchVendorData(vendorId);

        } else {
            this.fetchAvailableProviderCredentials();
        }

        this.setState({
            vendor_name: '',
            organization_id: '',
            vendor_type: '',
            external_id: '',
            vendor_phone: ''
        });
    };

    handleCloseDialog = () => {
        this.setState({
            openDialog: false
        });
        this.fetchPhoneNumbers(this.state.selectedCapability, this.state.selectedProviderForBuyingPhone);
    };

    buyPhoneNumber = () => {
        this.setState({
            selectedCallPhoneNumber: this.state.selectedCapability === 'CALL' ? this.state.selectedNumberToBuy : '',
            selectedSmsPhoneNumber: this.state.selectedCapability === 'SMS' ? this.state.selectedNumberToBuy : '',
        });

        const data = {
            phone_number: this.state.selectedNumberToBuy,
            provider_credential_id: this.state.selectedProviderForBuyingPhone,
            status: 'active',
            // @todo guilhermeblanco this was capabilities: [this.state.selectedCapability], but we need to purchase Twilio phones for both SMS and Call
            capabilities: ['SMS', 'CALL']
        };

        ProviderPhoneService.buyPhoneNumber(data.provider_credential_id, data)
            .then(data => {
                this.setState({
                    openDialog: false
                });
                this.fetchPhoneNumbers(this.state.selectedCapability, this.state.selectedProviderForBuyingPhone);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    openDialog: false
                })
            })
    };

    addPhoneNumber = () => {
        const data = {
            phone_number: this.state.phoneNumber,
            provider_credential_id: this.state.selectedProviderForBuyingPhone,
            status: 'active',
            capabilities: [this.state.selectedCapability]
        };
        ProviderPhoneService.addPhoneNumber(data.provider_credential_id, data)
            .then(data => {
                this.setState({
                    openDialog: false
                });
                this.setState({phoneNumber: ''});
                this.fetchPhoneNumbers(this.state.selectedCapability, this.state.selectedProviderForBuyingPhone);
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    openDialog: false
                })
            });
    };

    extractAreaCodeFromVendorPhone = function () {
        var code = '';
        if (this.state.vendor && this.state.vendor.business_phone_number) {
            var phoneNumber = this.state.vendor.business_phone_number;
            var startPosition = phoneNumber.startsWith('+') ? 1 : 0;
            code = phoneNumber.substring(startPosition, 3 + startPosition);
        }
        this.setState({
            areaCode: code
        });
    };

    fetchAvailablePhoneNumbers = function (areaCode, providerId, capabilities, countryCode) {
        ProviderPhoneService.fetchAvailablePhoneNumbers(areaCode, providerId, capabilities, countryCode)
            .then(data => {
                this.setState({
                    availablePhones: data
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    availablePhones: []
                })
            });
    };

    getAvailablePhones = () => {
        this.fetchAvailablePhoneNumbers(this.state.areaCode, this.state.selectedProviderForBuyingPhone,
            this.state.selectedCapability, this.state.countryCode);
    };

    drawBuyPhoneDialog() {
        const countries = [
            {
                name: "Canada",
                code: "CA"
            },
            {
                name: "USA",
                code: "US"
            }
        ];
        return this.state.selectedProviderName === 'RINGCENTRAL'
            ? (
                <Dialog
                    onClose={this.handleCloseDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openDialog}
                    className="dialog"
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
                        Add Phone Number
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            id="outlined-search"
                            label="Phone number"
                            className="l-width--full"
                            onChange={this.addPhoneChange}
                        />
                    </DialogContent>
                    <p className="e-tip">
                        Please, use the next format:<br/>
                        +XYYYZZZZZZZ <br/>
                        X - Country code (from 1 to 3 digits)<br/>
                        YYY - Area code (3 digits) <br/>
                        ZZZZZZZZ - Phone Number (7 digits)
                    </p>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.addPhoneNumber} color="primary"
                                disabled={!this.state.addPhoneValid}>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            )
            : (
                <Dialog
                    onClose={this.handleCloseDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openDialog}
                    className="dialog"
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleCloseDialog}>
                        Buy Phone Number
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="dialog-text">
                            Input Area Code to see available phone numbers
                        </DialogContentText>
                        <br/>
                        <TextField
                            id="outlined-search"
                            label="Area Code"
                            className="search-dialog-small"
                            value={this.state.areaCode}
                            onChange={this.handleChange('areaCode')}
                            type="number"
                        />
                        &nbsp;&nbsp;&nbsp;
                        <TextField
                            id="outlined-search"
                            label="Country Code"
                            select
                            className="search-dialog"
                            value={this.state.countryCode}
                            onChange={this.handleChange('countryCode')}
                        >
                            <MenuItem value="" disabled>Select Country</MenuItem>
                            {
                                countries.map(country => (
                                    <MenuItem value={country.code} key={country.code}>{country.name}</MenuItem>
                                ))
                            }
                        </TextField>
                        &nbsp;&nbsp;&nbsp;
                        <Button variant="outlined" disabled={!this.state.areaCode || !this.state.countryCode}
                                onClick={this.getAvailablePhones}>
                            Search
                        </Button>
                        <br/><br/>
                        <FormControl className="select-dialog">
                            <TextField
                                select
                                label="Available Phone Numbers"
                                value={this.state.selectedNumberToBuy}
                                onChange={this.handleChange('selectedNumberToBuy')}
                                className="select-field"
                                disabled={this.state.availablePhones.length === 0}
                            >
                                <MenuItem value="" disabled>Select Number to Buy</MenuItem>
                                {this.state.availablePhones.map(name => (
                                    <MenuItem value={name.phone_number} key={name.id}>{name.phone_number}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.buyPhoneNumber} color="primary"
                                disabled={!this.state.selectedNumberToBuy}>
                            Save changes
                        </Button>
                    </DialogActions>
                </Dialog>
            );
    }

    fetchAvailableCapabilities() {
        ProviderCredentialService.getCapabilities()
            .then((data) => {
                this.setState({
                    availableCapabilities: data
                });
                data.forEach(cap => {
                    const map = this.state.vendorCapabilitiesMap;

                    map.set(cap, null);
                    this.setState({
                        vendorCapabilitiesMap: map
                    });
                });
            })
            .catch((error) => {
                this.setState({
                    error: error.message
                });
            })
    };

    fetchVendorProviderCredentials = (vendorId) => {
        VendorCredentialService.getCredentialsForVendor(vendorId)
            .then((data) => {
                this.setState({
                    vendorCredentials: data,
                });
                data.forEach(credential => {
                    const map = this.state.vendorCapabilitiesMap;
                    map.set(credential.capability, credential.provider_credential_id);

                    if (credential.capability === 'SMS') {
                        this.setState({
                            smsProvider: credential.provider_credential_id,
                            smsProviderInitial: credential.provider_credential_id
                        });
                        this.fetchPhoneNumbers('SMS', credential.provider_credential_id);
                    } else if (credential.capability === 'CALL') {
                        this.setState({
                            callProvider: credential.provider_credential_id,
                            callProviderInitial: credential.provider_credential_id
                        });
                        this.fetchPhoneNumbers('CALL', credential.provider_credential_id);
                    } else if (credential.capability === 'EMAIL') {
                        this.setState({
                            emailProvider: credential.provider_credential_id,
                            emailProviderInitial: credential.provider_credential_id
                        });
                    }

                    const checks = this.state.checked;
                    checks[credential.capability] = credential.enabled;
                    this.setState({
                        vendorCapabilitiesMap: map,
                        checked: checks
                    });
                });
            })
            .catch((error) => {
            });
    };

    fetchAvailableVendorTypes() {
        VendorService.getAllVendorTypes()
            .then((data) => {
                this.setState({
                    availableTypes: data,
                    loading: false
                })
            })
            .catch((error) => {
                this.setState({
                    error: error.message,
                    loading: false
                })
            });
    };

    fetchVendorProviderPhones = (vendorId) => {
        VendorProviderPhoneService.getVendorProviderPhones(vendorId)
            .then(data => {
                this.setState({
                    vendorProviderPhones: data
                });
                data.forEach(providerPhone => {
                    if (providerPhone.capability === "SMS") {
                        this.setState({
                            smsPhone: providerPhone.provider_phone_id
                        });
                    } else if (providerPhone.capability === "CALL") {
                        this.setState({
                            callPhone: providerPhone.provider_phone_id
                        });
                    }
                });
            })
            .catch(error => {
            })
    };

    getVendorCredentialById = function (id) {
        var result;
        this.state.vendorCredentials.forEach(credential => {
            if (credential.provider_credential_id === id) {
                result = credential;
            }
        });
        return result;
    };

    isDisabledBuyPhoneButton = function (capability, providerName) {
        var credential = null;
        if (capability === 'SMS' && this.state.smsProvider) {
            credential = this.state.vendorCredentials.length > 0
                ? this.getVendorCredentialById(this.state.smsProvider)
                : this.getAvailableCredentialsById(this.state.smsProvider);
        } else if (capability === 'CALL' && this.state.callProvider) {
            credential = this.getVendorCredentialById(this.state.callProvider) || this.getAvailableCredentialsById(this.state.callProvider);
        }
        return !(credential && credential.provider === providerName);
    };

    getAvailableCredentialsById = function (id) {
        var result;
        this.state.availableCredentials.forEach(credential => {
            if (credential.id === id) {
                result = credential;
            }
        });
        return result;
    };

    fetchVendorData = (vendorId) => {
        VendorService.getVendorById(vendorId)
            .then((data) => {
                this.fetchAvailableProviderCredentials(data.organization_id);
                this.fetchVendorProviderPhones(vendorId);
                this.setState({
                    vendor: data,
                    vendor_name: data.name,
                    vendor_type: data.vendor_type,
                    organization_id: data.organization_id,
                    external_id: data.external_id,
                    loading: false,
                    hasError: false,
                    vendor_phone: data.business_phone_number || ''
                });
            })
            .catch((error) => {
                this.setState({
                    loading: false
                });
            });
    };

    fetchAvailableProviderCredentials = (organizationId) => {
        var obj = organizationId
            ? ProviderCredentialService.getAvailableCredentialsForOrganization(organizationId)
            : ProviderCredentialService.getAllCredentials();
        obj
            .then((data) => {
                this.setState({
                    availableCredentials: data
                });
            })
            .catch((error) => {
            });
    };

    handleSubmit(event) {
        event.preventDefault();
        const data = {
            name: this.state.vendor_name,
            vendor_type: this.state.vendor_type,
            organization_id: this.state.organization_id,
            external_id: this.state.external_id,
            business_phone_number: this.state.vendor_phone
        };

        const vendor = this.state.vendor;
        if (vendor && vendor.id) {
            data['id'] = vendor.id;
            this.saveVendorProviderCredentials(vendor.id);
            this.saveVendorProviderPhones(vendor.id);
        }

        this.saveVendorData(data);
    };

    saveVendorProviderPhones(vendorId) {
        if (this.state.smsPhone) {
            const data = {
                vendor_id: vendorId,
                capability: 'SMS',
                provider_phone_id: this.state.smsPhone
            };

            var actualSmsPhone = this.getActualProviderPhoneById('SMS', this.state.smsPhone);

            if (actualSmsPhone) {
                data['id'] = actualSmsPhone.id;
            }

            VendorProviderPhoneService.saveVendorProviderPhone(vendorId, data)
                .then(data => {})
                .catch(error => {});
        }

        if (this.state.callPhone) {
            const data = {
                vendor_id: vendorId,
                capability: 'CALL',
                provider_phone_id: this.state.callPhone
            };

            var actualCallPhone = this.getActualProviderPhoneById('CALL', this.state.callPhone);

            if (actualCallPhone) {
                data['id'] = actualCallPhone.id;
            }

            VendorProviderPhoneService.saveVendorProviderPhone(vendorId, data)
                .then(data => {})
                .catch(error => {});
        }
    };

    getActualProviderPhoneById = function (capability, id) {
        var result;

        this.state.vendorProviderPhones.forEach(phone => {
            if (phone.provider_phone_id === id && phone.capability === capability) {
                result = phone;
            }
        });

        return result;
    };

    saveVendorProviderCredentials(vendorId) {
        if (this.state.smsProvider) {
            const data = {
                vendor_id: vendorId,
                capability: "SMS",
                provider_credential_id: this.state.smsProvider,
                enabled: this.state.checked['SMS']
            };

            var actualSmsProvider = this.getActualProviderByType('SMS');

            if (actualSmsProvider) {
                data['id'] = actualSmsProvider.id;
            }

            VendorCredentialService.saveVendorProviderCredential(vendorId, data)
                .then((data) => {})
                .catch((error) => {});
        }

        if (this.state.callProvider) {
            const data = {
                vendor_id: vendorId,
                capability: "CALL",
                provider_credential_id: this.state.callProvider,
                enabled: this.state.checked['CALL']
            };

            var actualCallProvider = this.getActualProviderByType('CALL');

            if (actualCallProvider) {
                data['id'] = actualCallProvider.id;
            }

            VendorCredentialService.saveVendorProviderCredential(vendorId, data)
                .then((data) => {})
                .catch((error) => {});
        }

        if (this.state.emailProvider) {
            const data = {
                vendor_id: vendorId,
                capability: "EMAIL",
                provider_credential_id: this.state.emailProvider,
                enabled: this.state.checked['EMAIL']
            };

            var actualEmailProvider = this.getActualProviderByType('EMAIL');

            if (actualEmailProvider) {
                data['id'] = actualEmailProvider.id;
            }

            VendorCredentialService.saveVendorProviderCredential(vendorId, data)
                .then((data) => {})
                .catch((error) => {});
        }
    };

    getActualProviderByType = function (type) {
        this.state.vendorCredentials.forEach(credential => {
            if (credential.capability === type) {
                return credential;
            }
        });
        return null;
    };

    drawPhoneNumberSelect = function (isView, type) {

        if (type === "SMS") {
            return (
                <TextField
                    id="filled-select-phone"
                    select
                    label="Phone Number"
                    className="vendor-phone-select"
                    margin="normal"
                    disabled={isView}
                    value={
                        this.state.selectedSmsPhoneNumber !== '' && this.state.smsPhoneNumbers.find(p => p.phone_number === this.state.selectedSmsPhoneNumber)
                        ? this.state.smsPhoneNumbers.find(p => p.phone_number === this.state.selectedSmsPhoneNumber).id : this.state.smsPhone
                    }
                    onChange={this.handlePhoneNumber('SMS')}
                >
                    <MenuItem value="" disabled>Select Phone Number</MenuItem>
                    {this.state.smsPhoneNumbers
                        .filter(phone => phone.capabilities.includes('SMS'))
                        .map(phone => (
                            <MenuItem key={phone.id} value={phone.id}>
                                {phone.phone_number}
                            </MenuItem>
                        ))
                    }
                </TextField>
            )
        } else if (type === 'CALL') {
            return (
                <TextField
                    id="filled-select-phone"
                    select
                    label="Phone Number"
                    className="vendor-phone-select"
                    margin="normal"
                    disabled={isView}
                    value={
                        this.state.selectedCallPhoneNumber !== '' && this.state.callPhoneNumbers.find(p => p.phone_number === this.state.selectedCallPhoneNumber)
                            ? this.state.callPhoneNumbers.find(p => p.phone_number === this.state.selectedCallPhoneNumber).id : this.state.callPhone
                    }
                    onChange={this.handlePhoneNumber('CALL')}
                >
                    <MenuItem value="" disabled>Select Phone Number</MenuItem>
                    {this.state.callPhoneNumbers
                        .filter(phone => phone.capabilities.includes('CALL'))
                        .map(phone => (
                            <MenuItem key={phone.id} value={phone.id}>
                                {phone.phone_number}
                            </MenuItem>
                        ))
                    }
                </TextField>
            );
        }
    };

    saveVendorData(data) {

        if (this.state.vendor) {
            VendorService.updateVendor(data)
                .then((data) => {
                    window.location.href = "/vendors/" + data.id + "/view";
                })
                .catch(error => {
                    this.setState({
                        error: error.message,
                        loading: false
                    });
                })
        } else {
            VendorService.saveVendor(data)
                .then((data) => {
                    window.location.href = "/vendors/" + data.id + "/view";
                })
                .catch(error => {
                    this.setState({
                        error: error.message,
                        loading: false
                    });
                })
        }
    };

    deleteVendorCredential = (vendorId, credentialId) => {
        VendorCredentialService.deleteVendorProviderCredential(vendorId, credentialId)
            .then((data) => {
                window.location.reload();
            })
            .catch((error) => {
                window.location.reload();
            });
    };

    handleChange = name => event => {
        var isValid = true;
        if (name === 'vendor_type' || this.state.vendor_type) {
            isValid = false;
        }
        this.setState({
            [name]: event.target.value,
            hasError: isValid
        });
    };

    addPhoneChange = event => {
        const reg = /^[+]\d{11,13}$/;
        const value = String(event.target.value).trim();
        const isValid = !!value.match(reg);

        if (isValid && !this.state.addPhoneValid) {
            this.setState({addPhoneValid: true});
            this.setState({phoneNumber: value});
        } else if (!isValid && this.state.addPhoneValid) {
            this.setState({addPhoneValid: false});
        }
    };

    unselectProvider(name) {
        switch(name) {
            case 'SMS':
                this.setState({smsProvider: ''});
                return;
            case 'CALL':
                this.setState({callProvider: ''});
                return;
            case 'EMAIL':
                this.setState({emailProvider: ''});
                return;
        }
    }

    handleProvider = name => event => {

        if (event.target.value === 'unselect') return this.unselectProvider(name);

        const providerId = event.target.value;
        const selectedProvider = this.state.availableCredentials.find(ac => ac.id === providerId).provider;

        if (name === 'SMS') {
            this.setState({
                smsProvider: providerId
            });
            this.fetchPhoneNumbers('SMS', providerId);
            this.state.vendorProviderPhones.forEach(providerPhone => {
                if (providerPhone.providerPhone && providerPhone.provider_credential_id === providerId) {
                    this.setState({
                        smsPhone: providerPhone.providerPhone.provider_phone_id
                    });
                }
            });
            this.setState({selectedSmsProvider: selectedProvider});
        } else if (name === 'CALL') {
            this.setState({
                callProvider: providerId
            });
            this.fetchPhoneNumbers('CALL', providerId);
            this.state.vendorProviderPhones.forEach(providerPhone => {
                if (providerPhone.provider_credential_id === providerId) {
                    this.setState({
                        callPhone: providerPhone.provider_phone_id
                    });
                }
            });
            this.setState({selectedCallProvider: selectedProvider});
        } else {
            this.setState({
                emailProvider: providerId
            });
            this.setState({selectedEmailProvider: selectedProvider});

        }
    };

    fetchPhoneNumbers(type, credentialId) {
        ProviderPhoneService.getAllPhonesForCredential(credentialId, this.state.vendorId)
            .then((data) => {
                if (type === 'SMS') {
                    this.setState({
                        smsPhoneNumbers: data
                    });
                } else if (type === 'CALL') {
                    this.setState({
                        callPhoneNumbers: data
                    });
                }
            })
            .catch((error) => {
            });
    };

    handleSwitch = name => event => {
        const checks = this.state.checked;
        checks[event.target.value] = event.target.checked;
        this.setState({
            checked: checks
        })
    };

    getCheckedValue = name => {
        return this.state.checked[name] ? true : false;
    };

    drawBuyPhoneNumberButton(isView, capability) {
        const provider = capability === 'SMS' ? this.state.selectedSmsProvider
            : capability === 'CALL' ? this.state.selectedCallProvider
                : this.state.selectedEmailProvider;

        if (capability !== 'EMAIL') {
            return provider !== 'RINGCENTRAL' ? (
                    <Button variant="outlined" disabled={isView || this.isDisabledBuyPhoneButton(capability, 'TWILIO')}
                            onClick={() => this.handleOpenDialog(capability, provider)}>
                        Buy a Phone Number
                    </Button>)
                : (<Button variant="outlined"
                           onClick={() => this.handleOpenDialog(capability, provider)}>
                    Add a Phone Number
                </Button>);
        }
        return "";
    }

    handleOpenDialog = function (capability, providerName) {
        var provider = capability === 'SMS'
            ? this.state.smsProvider
            : this.state.callProvider;
        this.setState({
            openDialog: true,
            selectedNumberToBuy: '',
            selectedProviderForBuyingPhone: provider,
            selectedCapability: capability,
            selectedProviderName: providerName
        });

        this.extractAreaCodeFromVendorPhone();
    };

    handlePhoneNumber = name => event => {
        if (name === 'SMS') {
            this.setState({selectedSmsPhoneNumber: ''});
            this.setState({
                smsPhone: event.target.value
            });
        } else if (name === 'CALL') {
            this.setState({selectedCallPhoneNumber: ''});
            this.setState({
                callPhone: event.target.value
            });
        }
    };

    render() {
        const {vendor, loading, error} = this.state;
        const isView = !!this.props.isView;

        const footerButtons = !isView ? (
            <Grid container spacing={24}>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <Grid container spacing={16}>
                        <Grid item xs={2}/>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="primary"
                                    href={process.env.PUBLIC_URL + (vendor ? ('/vendors/' + vendor.id + "/view") : '/vendors')}
                                    className="form-buttons">Cancel</Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" color="primary" type="submit" className="form-buttons"
                                    disabled={this.state.hasError}>{vendor ? 'Save' : 'Create'}</Button>
                        </Grid>
                        <Grid item xs={2}/>
                    </Grid>
                </Grid>
                <Grid item xs={4}/>
            </Grid>
        ) : (<div></div>);

        const activeMenu = isView ? (<ActionMenuVendor vendor={vendor} className="menu"/>) : (<div></div>);

        const providerCredentials = vendor ? (
            <Grid item xs={12}>
                <Typography variant="h6">Provider Credentials</Typography>
                {this.state.availableCapabilities.map(capability => (
                       <div key={capability}>
                           <FormGroup row className="provider-row">
                               <FormControlLabel
                                   control={
                                       <Switch
                                           checked={this.getCheckedValue(capability)}
                                           onChange={this.handleSwitch(this, true)}
                                           value={capability}
                                           color="primary"
                                           disabled={isView}
                                       />
                                   }
                               />
                               <TextField
                                   id="filled-select-capability"
                                   label="Capability"
                                   className="vendor-credentials-input"
                                   value={capability}
                                   margin="normal"
                                   disabled
                               />
                               &nbsp;&nbsp;&nbsp;
                               <TextField
                                   id="filled-select-provider"
                                   select
                                   label="Provider Credential"
                                   className="vendor-credentials-select"
                                   value={capability === 'SMS' ? this.state.smsProvider : (capability === 'CALL' ? this.state.callProvider : this.state.emailProvider)}
                                   margin="normal"
                                   onChange={this.handleProvider(capability)}
                                   disabled={isView}>
                                   <MenuItem value="unselect" disabled={capability === 'SMS'
                                       ? this.state.smsProviderInitial !== ''
                                       : (capability === 'CALL' ? this.state.callProviderInitial !== '' : this.state.emailProviderInitial !== '')}>Select Provider Credential</MenuItem>
                                   {this.state.availableCredentials
                                       .filter(cred => cred.capabilities.includes(capability))
                                       .map(creds => (
                                           <MenuItem key={creds.id} value={creds.id}>
                                               {creds.name}
                                           </MenuItem>
                                       ))}
                               </TextField>
                               {/** Provider phone numbers */}&nbsp;&nbsp;&nbsp;
                               {this.drawPhoneNumberSelect(isView, capability)}
                               &nbsp;&nbsp;&nbsp;
                               {this.drawBuyPhoneNumberButton(isView, capability)}
                           </FormGroup>

                       </div>
                   )
                )}
            </Grid>
        ) : (<div></div>)

        if (loading) {
            return <div className="loading-container"><CircularProgress/></div>
        }

        if (error) {
            return <div className='error'>{error}</div>
        }

        return (
            <div>
                <CustomToolbar/>
                {this.drawBuyPhoneDialog()}
                <Toolbar disableGutters={true}>
                    <Typography variant="h5"
                                style={{flexGrow: 1}}>{isView && vendor ? ('Vendor ' + vendor.name) : (vendor ? ('Edit Vendor ' + vendor.name) : 'Create New Vendor')}</Typography>
                </Toolbar>
                <Divider/>
                <form onSubmit={this.handleSubmit}>
                    <Grid container spacing={24} className="active">
                        <Grid item xs={3}>
                            <TextField
                                id="name"
                                label="Name"
                                value={this.state.vendor_name}
                                onChange={this.handleChange('vendor_name')}
                                margin="normal"
                                className="form-input-label"
                                required
                                disabled={isView}/>

                            <br/>
                            <TextField
                                id="select-vendor-type"
                                select
                                label="Select"
                                className="form-input-label"
                                value={this.state.vendor_type}
                                onChange={this.handleChange('vendor_type')}
                                helperText="Please select vendor type"
                                margin="normal"
                                disabled={isView}>
                                {this.state.availableTypes.map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <br/>
                            <TextField
                                id="phone"
                                label="Business Phone"
                                value={this.state.vendor_phone}
                                onChange={this.handleChange('vendor_phone')}
                                margin="normal"
                                className="form-input-label"
                                required={this.state.vendor_type !== 'USER'}
                                disabled={isView}/>
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                id="organization"
                                label="Organization ID"
                                value={this.state.organization_id}
                                margin="normal"
                                onChange={this.handleChange('organization_id')}
                                className="form-input-label"
                                required
                                disabled={isView || (!!vendor)}/>
                            <br/>
                            <TextField
                                id="external_id"
                                label="External ID"
                                value={this.state.external_id}
                                onChange={this.handleChange('external_id')}
                                margin="normal"
                                type="text"
                                required
                                className="form-input-label"
                                disabled={isView}/>
                        </Grid>

                        <Grid item xs={6}>
                            {activeMenu}
                        </Grid>

                        {/* Provider Credentials Section */}
                        {providerCredentials}
                    </Grid>

                    {footerButtons}

                </form>
            </div>
        );
    };
};

export default VendorDialog;