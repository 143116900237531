import environment                      from '../../app.environment';

const API_URL = environment.REACT_APP_API_URL;

function handleResponse(response) {
  return response.json()
    .catch((response) => {

      return Promise.reject(response.status);
    }).then(json => {

    if (response.ok) {
      return json;
    }

    return Promise.reject(json);
  });
};

const VendorProviderPhoneService = {

  getVendorProviderPhones: async function(vendorId) {
    return fetch(`${API_URL}/vendors/${vendorId}/phones`)
      .then(handleResponse)
  },

  saveVendorProviderPhone: async function(vendorId, data) {
    let reqOptions = {
      method: "post",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(data)
    };
    return fetch(`${API_URL}/vendors/${vendorId}/phones`, reqOptions)
      .then(handleResponse)
  }
};

export default VendorProviderPhoneService;