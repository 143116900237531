import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ProviderCredentialService from "../../service/ProviderCredentialService";
import Confirmation from '../../common/Confirmation';

const options = [
  'Edit',
  'Delete'
];

const ITEM_HEIGHT = 48;

class ActionMenuProviderCredential extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      error: null,
        isConfirmationOpen: false
    };

    this.deleteHandler = this.deleteHandler.bind(this);
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  deleteHandler() {
      ProviderCredentialService.deleteCredentials(this.props.credential.id)
        .then((data) => {
           window.location.href="/credentials";
        })
        .catch((error) => {
           window.location.href="/credentials";
        });
  }

  handleClose = (e) => {
    this.setState({ anchorEl: null });
    var action = e.currentTarget.textContent;
    if (action === options[0]) {
      window.location.href="/credentials/" + this.props.credential.id + "/edit";
    } else if (action === options[1]) {
        this.setState({isConfirmationOpen: true});
    }
  };


  render() {
    const {anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div className="menu-hover">
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className="menu"
        >
          <MoreVertIcon className="menu"/>
        </IconButton>
        <b className="menu">Actions</b>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: 200
            },
          }}
        >
            <MenuItem key="Edit" onClick={this.handleClose}>
              <EditIcon/>Edit
            </MenuItem>
            <MenuItem key="Delete" onClick={this.handleClose}>
              <DeleteIcon/>Delete
            </MenuItem>

          ))}
        </Menu>

          <Confirmation
              isOpen={this.state.isConfirmationOpen}
              confirm={this.deleteHandler}
              closeChange={() => this.setState({isConfirmationOpen: false})}
          />
      </div>
    );
  }
}

export default ActionMenuProviderCredential;