import React from 'react';
import {Link} from 'react-router-dom';

import {Typography, Button} from '@material-ui/core';
import CustomToolbar from "./CustomToolbar";

const NotFound = () => {
  return (
    <div className="not-found">
      <CustomToolbar/>
      <br/>
      <Typography variant="h5">Ooops! page not found</Typography>
      <br/>
      <Link to="/" style={{ textDecoration: 'none'}}><Button variant="outlined" color="primary">Go to homepage</Button></Link>
    </div>
  )
};

export default NotFound;