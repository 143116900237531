import React from 'react';
import {Link} from 'react-router-dom';
import {Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Typography} from '@material-ui/core';

class ProviderCredentialTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50
    }
  };

  handleChangePage = (event, page) => {
    this.setState({page: page});
  };

  handleChangeRowsPerPage = event => {
    const newValue = event.target.value;
    this.setState({rowsPerPage: newValue});
  };

  render() {
    const {credentials} = this.props;
    const {rowsPerPage, page} = this.state;

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Provider Type</b></TableCell>
              <TableCell><b>Organization ID</b></TableCell>
              <TableCell><b>Capabilities</b></TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
          {credentials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((credential) => (
            <TableRow key={credential.id}>
              <TableCell>
                <Link to={{pathname: "/credentials/" + credential.id + "/view"}}>
                  <Typography>{credential.name}</Typography>
                </Link>
              </TableCell>
              <TableCell>{credential.provider}</TableCell>
               <TableCell>{credential.organization_id}</TableCell>
              <TableCell>{credential.capabilities.join(', ')}</TableCell>
              <TableCell/>
            </TableRow>
           ))}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={credentials.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
      </div>
    );
  };
};

export default ProviderCredentialTable;