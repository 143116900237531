import environment                      from '../../app.environment';

const API_URL = environment.REACT_APP_API_URL;

function handleResponse(response) {
  return response.json()
    .catch((response) => {

      return Promise.reject(response.status);
    }).then(json => {

    if (response.ok) {
      return json;
    }

    return Promise.reject(json);
  });
};

const VendorProviderCredentialService = {

    getCredentialsForVendor: async function(vendorId) {
      return fetch(`${API_URL}/vendors/${vendorId}/credentials`)
        .then(handleResponse)
    },

    getCredentialsForVendorWithCapability: async function(vendorId, capability) {
      return fetch(`${API_URL}/vendors/${vendorId}/credentials?capability=${capability}`)
        .then(handleResponse)
    },

    deleteVendorProviderCredential: async function(vendorId, credentialId) {
      let reqOptions = {
        method: "delete"
      };
      return fetch(`${API_URL}/vendors/${vendorId}/credentials/${credentialId}`, reqOptions)
       .then(handleResponse)
    },

    saveVendorProviderCredential: async function(vendorId, data) {
      let reqOptions = {
          method: "post",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(data)
        };
      return fetch(`${API_URL}/vendors/${vendorId}/credentials`, reqOptions)
          .then(handleResponse)
    }
};

export default VendorProviderCredentialService;