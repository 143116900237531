import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Table, TableHead, TableBody, TableRow, TableCell, TablePagination} from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

import VendorCredentialService from "../../service/VendorProviderCredentialService";
import EditIcon from '@material-ui/icons/Edit';

class VendorTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 50,
      credentialsMap: new Map()
    }
    var vendors = props.vendors;
  };

  isCredentialsForVendorExists = (vendorId) => {
      var status = this.state.credentialsMap.get(vendorId);
      if (!status) {
          VendorCredentialService.getCredentialsForVendor(vendorId)
          .then((data) => {
            this.onItemUpdate(vendorId, Array.isArray(data) && data.length > 0)
          })
          .catch((error) => {
            this.onItemUpdate(vendorId, false);
          });
      }
  };

  onItemUpdate = function(vendorId, state) {
      const map = this.state.credentialsMap;
      map.set(vendorId, state);
      this.setState({credentialsMap: map});
  };

  handleChangePage = (event, page) => {
    this.setState({page: page});
  };

  handleChangeRowsPerPage = event => {
    const newValue = event.target.value;
    this.setState({rowsPerPage: newValue});
  };

  render() {
    const {vendors} = this.props;
    const {rowsPerPage, page} = this.state;

    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><b>Name</b></TableCell>
              <TableCell><b>Type</b></TableCell>
              <TableCell><b>Organization ID</b></TableCell>
              <TableCell><b>External ID</b></TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {vendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vendor) => (
            <TableRow key={vendor.id}>
              <TableCell>
                <Link to={{pathname: "/vendors/" + vendor.id + "/view"}}>
                  {vendor.name}
                </Link>
              </TableCell>
              <TableCell>
                {vendor.vendor_type}
              </TableCell>
              <TableCell>
                {vendor.organization_id}
              </TableCell>
              <TableCell>
                {vendor.external_id}
              </TableCell>
              <TableCell>
                <Link to={{pathname: "/vendors/" + vendor.id + "/edit"}}>
                <span className="e-link"><EditIcon/>Edit</span>
                </Link>
              </TableCell>
            </TableRow>

            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={vendors.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}/>
      </div>
    );
  };

};

export default withRouter(VendorTable);