/**
 * Application's Environment
 */

const envFile = require('./env.json');

const env = process.env.REACT_APP_UI_ENV || 'local';
const envVariables = envFile[env];

const environment = Object.assign(envVariables, process.env);

export default environment;